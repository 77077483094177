/* Layout
========================================================================== */
.main,
.main-wrapper{
  height: 100%;
}

section {
  width: calc(100%);
}

.opener{
  min-height: 100vh;
  padding: $padding;
}

.project__container {
  height: 100%;
  padding-top: $padding * 6;
  @include breakpoint(md) {
    // padding-top: $padding * 8;
  }

  .left-half,
  .right-half{
    position: relative;
    overflow-y: scroll;
    padding-bottom: $padding * 8;
    @include breakpoint(sm) {
      width: 50%;
      float: left;
      height: 100%;
    }

    a{
      border-bottom: 1px solid $grey;
    }


    p,
    h1{
      margin: 0;
    }
    .heading,
    .content{
      padding: $padding;
    }
    .contact{
      border-top: $border-black;
      p{
        padding: $padding;
      }
    }
    .contact-heading{
      border-bottom: $border-black;
    }
    .content{
      p{
        padding-bottom: $padding;
      }
    }
    .credits{
      padding: $padding;
      border-top: $border-black;
      border-bottom: $border-black;
      font-size: 70%;
    }
  }

  .left-half {
    @include breakpoint(sm) {
      border-right: $border-black;
    }
  }

  .right-half {
    border-top: $border-black;
    @include breakpoint(sm) {
      border: none;
    }
    img{
      padding: $padding;
    }
  }
}

.scroll-to-images{
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: $padding * 2;
  z-index: 100;
  opacity: 0;
  visibility:hidden;
  transition: all 0.5s $ease;
  .scroll-to-images__wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      padding-left: $padding * 2;
      padding-right: $padding * 2;
      padding-top: $padding;
      padding-bottom: $padding;
      color: $white;
      background: $red;
      text-align: center;
      border-radius: 50px;
    }
  }
}

.scroll-to-images.active{
  opacity: 1;
  visibility: visible;
}

.blog__container{
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  // overflow: hidden;
  padding-top: $padding * 6;
  @include breakpoint(md) {
    // padding-top: $padding * 8;
  }

  article{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: $border-black;
    transition: opacity 1s $ease;
    @include breakpoint(sm) {
      width: 50%;
      border-right: $border-black;
    }
    @include breakpoint(xl) {
      width: 25%;
    }
    &:hover{
      color: $red;
    }
    img{
      width: 100%;
      // height: 100%;
      padding: $padding;
      // max-height: 250px;
    }
    h1{
      padding: $padding;
      display: block;
      margin: 0;
      border-top: $border-black;
    }
    time{
      padding: $padding;
      // font-family: $serif;
      display: block;
      border-top: $border-black;
      border-bottom: $border-black;
      font-size: 70%;
    }
    span{
      border-bottom: $border-grey;
    }
  }
}

.blog__intro{
  padding: $padding;
  padding-bottom: $padding * 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

.blog__intro--wrapper{

}

article p:before{
  content: "";
  float: left;
  background-color: #000;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 6px;
  margin-top: 5px;
}

article:hover p:before{
  background-color: $red;
}

footer{
  // display: none;
  border-top: $border-black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: $height * 5;
  padding: $padding;
  background-color: $white;
  position: fixed;
  bottom: 0;
  z-index: 100;
  vertical-align: text-bottom;
  // font-family: 'serif';
}

figure{
  // padding-bottom: $padding;
  // cursor: nesw-resize;
  // padding: $padding;
  border-bottom: $border-black;
  img{
    width: 100%;
    height: auto;
  }
}

figcaption{
  // font-family: $serif;
  color: $red;
  padding: $padding;
  // padding-bottom: $padding * 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 70%;
  border-top: $border-black;
}

figcaption:empty {
  display: none;
}

/* Animation
========================================================================== */
.fade-in {
  animation: fadeIn 1s ease-in;
}
.fade-out {
  transition: fadeOut 1s ease-in;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    // transform: translateY(25vh);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}

@keyframes fadeOut{
  from {
    opacity: 1;
    // transform: translateY(0);
  }
  to {
    opacity: 0;
    // transform: translateY(25vh);
  }
}
