.loader{
  width: calc(100%);
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  position: fixed;
  background: $white;
  cursor: progress;
    h2{
      padding: $padding;
      font-size: calc(4rem + 2.5vw);
      @include breakpoint(md) {
        font-size: calc(4rem + 8vw);
      }
    }
  }
