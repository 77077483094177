.index__wrapper{
  padding-top: 25vh;
  min-height: 100vh;
  width: 100%;
}

.index__header--container{
  position: sticky;
  top: 60px;
  border-top: $border-black;
  background-color: $white;
  color: $black;
  width: 100%;
  z-index: 100;
}

.index__header{
  display: flex;
  flex-direction: row;
  padding: $padding;
  border-bottom: $border-black;
  align-items: center;
  background: $white;
  height: 45px;
}

.index__header--filters{
  padding: $padding;
  border-bottom: $border-black;
  position: absolute;
  width: 100%;
  height: 45px;
  background: white;
  transform: translateY(-45px);
  transition: all 0.5s $ease;
  z-index: -1;
  &.active{
    transform: translateY(0);
    height:auto;
    .index__header--filter-wrapper{
      opacity: 1;
    }
  }
}

.index__header--filter-wrapper{
  opacity: 0;
  transition: opacity 0.05s linear;
}


.index__header--left,
.index__header--right{
  width: 50%;
}

.index__header--left,
.index__header--right{
  display: flex;
  flex-direction: row;
  .index__header--category,
  .index__header--year{
    width: calc(100% / 3);
  }
}

.index__header--right{
  justify-content: flex-end;
  @include breakpoint(sm){
    justify-content: flex-start;
  }
}

.index__header--project{
  width: calc(100% / 3 * 2);
  @include breakpoint(sm) {
    padding-right: 60px;
  }
}

.index__header--filter{
  align-self: flex-end;
  text-align: right;
  @include breakpoint(sm) {
    align-self: flex-start;
    text-align: left;
    width: calc(100% / 3);
  }
}

.index__header--year,
.index__header--category{
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}

.index__header--featured-image{
  display: none;
  @include breakpoint(sm) {
    width: calc(100% / 3 * 2);
    padding-right: 60px;
    display: block;
  }
}

ul.index__filter{
  width: 100%;
  transition: all $ease 0.2s;
  text-align: right;
  li{
    padding: $padding;
    border-bottom: $border-black;
    // &:last-of-type{
    //   border-bottom: none;
    // }
  }
  @include breakpoint(sm){
    text-align: left;
  }
}

.dropdown-content{
  display: none;
  padding: $padding;
  border-bottom: $border-black;
}

.dropdown-content.is-open {
  display: block;
}



.index__entry{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: $padding;
  border-bottom: $border-black;
  @include breakpoint(md){
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.index__entry:hover{
  .index__entry--right{
    .index__entry--images{
      img{
        filter: grayscale(0);
      }
    }
  }
}

.index__entry.inactive{
  .index__entry--right{
    .index__entry--images{
      opacity: 0.25;
    }
  }
}

.index__entry.inactive:hover{
  .index__entry--right{
    .index__entry--images{
      img{
        filter: grayscale(100);
      }
    }
  }
}

.index__entry--left{
  padding-bottom: $padding;
  @include breakpoint(md){
    padding-bottom: 0;
  }
}

.index__entry--left,
.index__entry--right{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @include breakpoint(md){
    width: 50%;
    flex-wrap: wrap;
  }
}


.index__entry--title{
  width: calc(75%);
  @include breakpoint(md) {
    width: calc(100% / 3 * 2);
    padding-right: 60px;
  }
}

.index__entry--tag{
  width: 100%;
  display: none;
  @include breakpoint(md) {
    // display: block;
    width: calc(100% / 3);
    padding: 0;
  }
}
.index__entry--year{
  display: block;
  width: calc(25%);
  text-align: right;
  @include breakpoint(md) {
    text-align: left;
    width: calc(100% / 3);
    padding-right: $padding;
  }
}

.index__entry--images{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
.index__entry--image-wrapper {
  position: relative;
  &:not(:last-child){
    margin-right: 10px;
  }
}

.index__entry--image{
  transition: all 0.25s $ease;
  background-color: $red;
  img{
    height: 150px;
    width: auto;
    display: inline-block;
    filter: grayscale(100);
    cursor: pointer;
  }
}


// .tag{
//   padding-bottom: $padding / 2;
// }

// .filter:before{
//   content: '\02193';
//   padding-right: $padding / 4;
// }
// .filter::before{
//   content: "";
//   // float: left;
//   display: inline-block;
//   background-color: $black;
//   border: 1px solid $black;
//   width: 12px;
//   height: 12px;
//   margin-right: 6px;
//   // margin-top: 5px;
// }
//
// .filter.active::before{
//   content: "";
//   // float: left;
//   display: inline-block;
//   background-color: $red;
//   border: 1px solid $red;
//
// }


.filter:not(:last-of-type):after {
  content: ', ';
}

.index__scroll-btn {
  display: block;
  @include breakpoint(md) {
    display: none;
  }
}


.index__container {
  height: 100%;
  width: 100%;
  position: relative;

  @include breakpoint(md){
    // padding-top: $padding * 13;
  }
  .index__left-half,
  .index__right-half{
    position: relative;
    overflow-y: scroll;
    // padding: $padding;
    padding-bottom: $padding * 8;
    @include breakpoint(md) {
      width: 50%;
      float: left;
      height: 100%;
    }
  }

  .index__left-half {
    @include breakpoint(sm) {
      border-right: $border-black;
    }
  }

  .index__right-half {
    border-top: $border-black;
    @include breakpoint(sm) {
      border: none;
    }
  }
}

.lazyload,
.lazyloading {
	opacity: 0;
  // transform: translateY(40px);
}

.lazyloaded {
	opacity: 1;
	transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.lazyloaded:nth-child(odd){
  transition: transform .85s ease-in-out, opacity .85s ease-in-out;
}
