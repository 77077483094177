@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

	@else if $class == xl {
		@media (min-width: 1440px) { @content; }
	}

	@else if $class == xxl {
		@media (min-width: 2560px) { @content; }
	}

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl, xxl";
  }
}

@keyframes blink {
  50% { opacity: 0; }
}

.blink {
  -webkit-animation: blink 1s ease-in-out infinite;
  -moz-animation: blink 1s ease-in-out infinite;
  animation: blink 1s ease-in-out infinite;
}
