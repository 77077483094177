nav{
	position: fixed;
	z-index: 300;
	// font-size: 5rem;
	width: 100%;
	background: $white;
	padding: $padding;
	height: 60px;
	// border-bottom: $border-black;
	@include breakpoint(md) {
		// height: $padding * 8;
	}
}

.nav__border{
	border-bottom: $border-black;
}

.nav__wrapper{
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-content: center;
	flex-direction: row;
	width: 100%;
	height: 100%;
	@include breakpoint(md) {
		justify-content: flex-start;
	}
}

.nav__title{
	width: 50%;
}

.nav__menu{
	width: 50%;
	margin-right: $margin;
	display: none;
	@include breakpoint(md) {
		display: flex;
		flex-direction: row;
		h1{
			width: calc(100vw / 3);
			padding: $padding;
		}
	}
}

.menu-btn{
	height: 60px;
	display: flex;
  justify-content: center;
  align-items: center;
	@include breakpoint(md) {
		display: none;
	}
	svg{
		max-width: 18px;
		width: 100%;
	}
}

.mobile-menu {
	position: fixed;
	transform: translateY(-110%);
	width: 100%;
	display: flex;
	transition: all 0.65s $ease;
	overflow: auto;
	background: white;
	margin-top: $padding * 6;
	z-index: 200;
	@include breakpoint(md) {
		display: none;
	}
	&.open {
		transform: translateY(0);
	}

}

.mobile-menu__wrapper{
	width: 100%;
}

.mobile-menu__menu-item {
	a {
		padding: $padding;
		border-bottom: $border-black;
		// height: $height * 6;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	&:first-of-type{
		a {
			border-top: $border-black;
		}
	}
}
