h1{
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: capitalize;
  // hyphens: auto;
  max-width: 50ch;
  @include breakpoint(sm) {
    font-size: 3.4rem;
  }
  @include breakpoint(md) {
    font-size: 3.4rem;
  }
  @include breakpoint(xxl) {
    font-size: 4.2rem;
  }
}

p{
  margin-bottom: $margin;
}

a{
  cursor: pointer;
  text-decoration: none;
  transition: color 0.6s $ease;
  vertical-align: baseline;
  &:hover{
    color: $red;
  }
}

.active{
  color: black;
  transition: color 0.6s $ease;
}
//
// .inactive{
//   color: light$grey;
// }

aside{
  a{
    // border-bottom: $rule;
    transition: 0.5s $ease;
    &:hover{
      color: $muted-color;
      // border-bottom: $muted-rule;
    }
  }
}

button,
button:hover,
button:active,
button:focus{
  background-color: none;
}

em{
  font-style: italic;
}

sup{
  font-size: 85%;
}

sup.link:after{
	content: '\02197';
	margin-left: 2px;
}

.alt::before{
  content: '\002B';
  vertical-align: text-top;
}

.serif{
  font-family: $serif;
  // font-size: calc(1.8rem + 0.5vw);
  // line-height: 1.2;
}

/* Lists
========================================================================== */
ul {
    list-style-type: none;
    // overflow: hidden;
    margin: 0;

    li{
      vertical-align: baseline;
      list-style: none;
    }
}

/* Form
========================================================================== */

select{
  background: none;
  // font-family: 'serif';
  // font-size: calc(1.8rem + 0.5vw);
  // line-height: 1.2;
}
