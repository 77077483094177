// @font-face {
//   font-family: 'Sans';
//   font-weight: normal;
//   font-style: normal;
//   src: local('Suisse'),
//        url('./fonts/SuisseIntl-Regular-WebS.woff2') format('woff2'),
//        url('./fonts/SuisseIntl-Regular-WebS.woff') format('woff');
// }

@font-face {
  font-family: 'Sans';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Gestalt-Regular.woff') format('woff');
}


@import 'base/index';

::-webkit-scrollbar {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box; }

* {
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: inherit;
  border: none;
  color: inherit;
  appearance: none;
  border-radius: 0;

  &:focus {
    outline: 0;
  }
}

html, body {
    margin: 0;
    height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 100%;
  font-size: 1.8rem;
  line-height: 1.2;
  font-family: $sans;
  background-color: $white;
  font-feature-settings:"kern" 1;
  font-kerning: normal;
  letter-spacing: 0.015em;
  word-spacing: 0.001em;
  @include breakpoint(sm) {
    font-size: 2.4rem;
  }

  color: $black;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

@import 'components/index';
@import 'photoswipe/photoswipe';
@import 'hamburgers/hamburgers';
